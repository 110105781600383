import React from "react";
import { FaFacebookF, FaInstagram, FaLinkedinIn } from "react-icons/fa";

function Footer() {
  return (
    <footer className="bg-gray-900 text-white p-4 py-8">
      <div className="container mx-auto px-4">
        <div className="flex flex-col md:flex-row justify-between items-center">
          <div className="mb-6 md:mb-0">
            <h2 className="text-2xl font-bold mb-4">Nirmaan</h2>
            <p>Nirmaan, 2024. All rights reserved.</p>
          </div>

          <div className="flex flex-col md:flex-row space-x-8">
            <div className="mb-6 md:mb-0">
              <h3 className="text-lg font-semibold mb-4">Menu</h3>
              <ul>
                <li>
                  <a href="#" className="hover:underline">
                    Home
                  </a>
                </li>
                <li>
                  <a href="#" className="hover:underline">
                    Services
                  </a>
                </li>
                <li>
                  <a href="#" className="hover:underline">
                    Portfolio
                  </a>
                </li>
              </ul>
            </div>

            <div className="mb-6 md:mb-0">
              <h3 className="text-lg font-semibold mb-4">Contact Us</h3>
              <p>Nirmaan.co</p>
              <p>(323) 9435</p>
              <p>Tegal, Indonesia</p>
            </div>

            <div>
              <h3 className="text-lg font-semibold mb-4">Social Media</h3>
              <ul className="flex space-x-4">
                <li>
                  <a href="#" className="hover:text-gray-300">
                    <FaFacebookF />
                  </a>
                </li>
                <li>
                  <a href="#" className="hover:text-gray-300">
                    <FaInstagram />
                  </a>
                </li>
                <li>
                  <a href="#" className="hover:text-gray-300">
                    <FaLinkedinIn />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;

import React, { useState } from "react";
import { Link } from "react-router-dom";

const Navbar = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <nav className="animate-rightToBottom flex items-center justify-between p-4 bg-white shadow-md ">
      <div className="flex items-center space-x-1">
        <div className="text-3xl font-bold" aria-label="Logo">
          🌿
        </div>
        <button className="text-sm font-semibold px-3 py-1 rounded-full bg-gray-100 hover:bg-gray-200">
          Explore
        </button>
      </div>
      <div className="space-x-6 hidden md:flex">
        <Link
          to="/"
          className="text-gray-700 hover:text-black"
          aria-label="Home"
        >
          Home
        </Link>
        <Link
          to="/about"
          className="text-gray-700 hover:text-black"
          aria-label="About Us"
        >
          About Us
        </Link>
      </div>
      <div className="md:hidden">
        <button
          onClick={toggleMobileMenu}
          className="p-2 rounded-md focus:outline-none focus:ring focus:ring-gray-300"
          aria-label="Toggle Mobile Menu"
        >
          {isMobileMenuOpen ? "✖️" : "☰"}
        </button>
      </div>
      <div
        className={`md:hidden ${
          isMobileMenuOpen ? "block" : "hidden"
        } absolute top-16 right-0 bg-white shadow-md w-full overflow-y-auto scrollbar-hide`}
      >
        <div className="flex flex-col space-y-2 p-4">
          <Link
            to="/"
            className="text-gray-700 hover:text-black"
            aria-label="Home"
          >
            Home
          </Link>
          <Link
            to="/about"
            className="text-gray-700 hover:text-black"
            aria-label="About Us"
          >
            About Us
          </Link>
        </div>
      </div>
      <Link
        to="/contact"
        className="px-4 py-2 border border-gray-700 rounded-full hover:bg-gray-100"
      >
        Contact us
      </Link>
    </nav>
  );
};

export default Navbar;

import React, { useState } from "react";
import image1 from "..//assests/image1.png"; // Assuming assets folder
import image2 from "../assests/image2.png";
import image3 from "../assests/image3.png";

const images = [image1, image2, image3];

const Carousel = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const goToPrevious = () => {
    const isFirstSlide = currentIndex === 0;
    const newIndex = isFirstSlide ? images.length - 1 : currentIndex - 1;
    setCurrentIndex(newIndex);
  };

  const goToNext = () => {
    const isLastSlide = currentIndex === images.length - 1;
    const newIndex = isLastSlide ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
  };

  return (
    <div className="relative bg-gradient-to-b from-white to-black">
      {/* Carousel Container */}
      <div className="relative w-full max-w-3xl mx-auto">
        {/* Background Overlay (Half White, Half Black) */}
        <div className="absolute inset-0 z-10">
          <div className="w-1/2 bg-white"></div>
          <div className="w-1/2 bg-black opacity-50"></div>
        </div>

        {/* Slide Container */}
        <div className="relative w-full h-96 overflow-hidden rounded-lg shadow-lg z-20">
          <img
            src={images[currentIndex]}
            alt="carousel slide"
            className="object-cover h-full w-full transition duration-500 ease-in-out"
          />
        </div>

        {/* Left Arrow */}
        <button
          onClick={goToPrevious}
          className="absolute top-1/2 left-6 transform -translate-y-1/2 bg-transparent hover:bg-gray-800 text-white p-2 rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-white z-30"
        >
          &larr;
        </button>

        {/* Right Arrow */}
        <button
          onClick={goToNext}
          className="absolute top-1/2 right-6 transform -translate-y-1/2 bg-transparent hover:bg-gray-800 text-white p-2 rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-white z-30"
        >
          &rarr;
        </button>

        {/* Dots Navigation */}
        <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 flex space-x-2 z-30">
          {images.map((_, index) => (
            <button
              key={index}
              onClick={() => setCurrentIndex(index)}
              className={`w-3 h-3 rounded-full bg-gray-400 ${
                index === currentIndex ? "bg-white" : ""
              } focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-white`}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Carousel;

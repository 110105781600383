import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Navbar from "./components/Navbar";
import OrderProject from "./components/Orderproject";
import Footer from "./components/Footer";
import Carousel from "./components/Carousel";
import ServicesSection from "./components/ServicesSection";
import ContactForm from "./pages/ContactForm"; // Import ContactForm
import AboutUs from "./pages/AboutUs";

function App() {
  return (
    <Router>
      <div className="App relative overflow-y-auto max-h-screen scrollbar-hide">
        <Navbar />
        <Routes>
          <Route
            path="/"
            element={
              <>
                <OrderProject />
                <Carousel />
                <ServicesSection />
              </>
            }
          />
          <Route path="/contact" element={<ContactForm />} />
          <Route path="/aboutus" element={<AboutUs />} />
          {/* Contact page route */}
        </Routes>
        <Footer />
        <ToastContainer />
      </div>
    </Router>
  );
}

export default App;

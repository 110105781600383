// OrderProject.js
import React from "react";

const OrderProject = () => {
  return (
    <div className="animate-fadeInUp flex flex-col md:flex-row items-center justify-center p-10 bg-white">
      {/* Left Section */}
      <div className="md:w-1/2 mb-6 md:mb-0 text-center md:text-left">
        <h2 className="text-gray-500 uppercase text-sm mb-4">Nirmaan design</h2>
        <h1 className="text-4xl md:text-5xl font-bold leading-tight mb-2">
          Creative Landscaping
        </h1>
        <h1 className="text-4xl md:text-5xl font-bold leading-tight mb-6">
          you can rely on
        </h1>
      </div>

      {/* Right Section */}
      <div className="md:w-1/3 space-y-6 text-center md:text-left">
        <p className="text-gray-600">
          Our creative landscape solutions create an aesthetically stunning
          garden or lawn design and inspire you to create magical moments that
          you will cherish!
        </p>
        <button className="px-6 py-3 bg-black text-white rounded-full hover:bg-gray-800 transition">
          Order a project
        </button>
      </div>
    </div>
  );
};

export default OrderProject;
